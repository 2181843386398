@import "src/assets/styles/index";

.home-page {
  .line-container {
    position: absolute;
    text-align: center;
    overflow: hidden;
    width: fit-content;
    left: 50%;
    transform: translateX(-50%);

    & > svg {
      display: inline-block;
    }

    &__2 {
      transform: translateX(-1%);
      z-index: 20;
    }


    &__3 {
      top: -86.5%;
    }

    &__4 {
      top: 50%;
      transform: translate(-50%, -50%);
    }

    &__5 {
      right: -1%;
      left: unset;
      transform: unset;
      top: -30%;
      @include xl {
        top: -2%;
      }
    }

    &__6 {
      right: -100%;
      left: unset;
      display: none;
      bottom: 99%;
      @include xl {
        display: block;
      }
      @include xxl {
        right: -81%;
      }
    }

    &__7 {
      top: 0;
      left: 0;
      transform: none;
    }

    &__8 {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      transform: none;
    }
  }

  &__leader-slider {
    .swiper-comp {
      &__btn {
        top: 38%;
        transform: translateY(-38%);
      }
    }
  }
}