@import "src/assets/styles/index";

.data-protection-page {
  &__content {
    &-wrapper {
      word-break: break-word;
      position: relative;
    }

    &-article {
      @apply w-full;
      @apply text-lg;
      @apply space-y-4;
      &__title {
        text-transform: uppercase;
        font-weight: 500;
      }

      &__list {
        @apply space-y-2;
        & > li {
          &:before {
            content: '*';
            padding-right: 6px;
          }
        }
      }
    }
  }
}