@import "../../assets/styles/index";

.modal-comp {
  & > div:first-child {
    display: inherit;

    & > div {
      margin: auto 0;
      width: 100%;
      background-color: $white !important;
    }
  }
}