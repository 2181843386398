.loading {
  &-container {
    z-index: 9999;
  }

  &-mask {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.6);
    inset: 0;
    margin: 0 !important;
  }
}