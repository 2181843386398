@import "../../../assets/styles/index";

.become-a-member {
  &__date-picker {
    .react-datepicker-wrapper {
      .react-datepicker__input-container {
        input {
          @apply bg-gray;
          @apply shadow-md;
          @apply border-none;
          @apply text-base;
          @apply text-left;
          @include sm {
            @apply text-base;
          }
        }
      }
    }
  }
}