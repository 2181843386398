@import "../../assets/styles/index";

.swiper-comp {
  padding-left: 20px;
  padding-right: 20px;
  @include lg {
    padding-left: 40px;
    padding-right: 40px;
  }

  &__btn {
    position: absolute;
    width: 16px;
    height: 16px;
    transform: translateY(50%);
    @include lg {
      width: 20px;
      height: 30px;
    }

    &-next {
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &-prev {
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .swiper {
    &-pagination {
      position: relative;
      margin-top: 10px;

      &-bullet {
        &-active {
          background-color: $primary;
        }
      }
    }
  }
}