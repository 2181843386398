@import "src/assets/styles/index";

.image-text-listing-diagram {
  color: $white;

  &__item {
    position: relative;
    margin: 0 auto;
    border-image-slice: 1;
    border-image-source: linear-gradient(26.03deg, rgba(152, 131, 19, 0) -132.29%, #988313 51.92%, rgba(152, 131, 19, 0) 228.61%);
    display: flex;
    flex-direction: column;
    padding: 32px;
    @include lg {
      flex-direction: row;
      max-width: 576px;
      padding: 100px 0;
    }
    @include xl {
      max-width: 768px;
    }

    &:nth-child(odd) {
      //border-left: 10px solid;

      &:first-child {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }

      .item {
        &-image {
          .image {
            @include lg {
              left: -50%;
            }
          }
        }
      }
    }

    &:nth-child(even) {
      //border-right: 10px solid;
      .line-container {
        transform: scaleX(-1);
      }

      @include lg {
        flex-direction: row-reverse;
      }

      &:last-child {
        padding-bottom: 0;
      }

      .item {
        &-image {
          .image {
            @include lg {
              right: -50%;
            }
          }
        }

        &-body {
          @include lg {
            align-items: end;
            text-align: right;
          }
        }
      }
    }

    &:not(:last-child) {
      //border-bottom: 10px solid;
    }

    .line-container {
      position: absolute;
      top: 0;
      left: 0;
    }

    .item {
      &-image {
        width: 100%;
        margin-bottom: 24px;
        @include lg {
          max-width: 230px;
          margin-bottom: 0;
        }
        @include xl {
          max-width: 364px;
        }

        &__wrapper {
          width: 100%;
          padding-bottom: 100%;
          position: relative;

          .image {
            width: 100%;
            height: 100%;
            top: 0;
            position: absolute;
            object-fit: cover;
            border-radius: 100%;
          }
        }
      }

      &-body {
        width: 100%;
        text-transform: uppercase;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        @include lg {
          max-width: 672px;
          align-items: start;
          text-align: left;
        }

        .name {
          font-size: 32px;
          margin-bottom: 16px;
          @include lg {
            margin-bottom: 32px;
          }
        }

        .position {
          font-size: 20px;
          margin-bottom: 32px;
          @include lg {
            margin-bottom: 64px;
          }
        }

        .description {
          font-size: 16px;

          &:before {
            content: '"';
          }

          &:after {
            content: '"';
          }
        }

        .social {
          &-icon {
            display: flex;
            margin-top: 34px;
            @include lg {
              margin-top: 68px;
            }

            &__container {
              width: 48px;
            }

            &__wrapper {
              width: 100%;
              padding-bottom: 100%;
              position: relative;
            }

            &__link {
              display: block;
              position: absolute;
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
            }
          }
        }
      }
    }
  }
}