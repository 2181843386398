@import "src/assets/styles/index";

.header-comp {
  @extend .transition-ease-in-out;
  position: absolute;

  &.active-sticky {
    position: fixed;
    top: -100%;
    animation: headerActiveSticky ease-in-out forwards;
    animation-duration: 1000ms;
  }

  .hamburger-button {
    width: 40px;
    position: relative;
    height: 29px;
    overflow: hidden;
    z-index: 40;

    span {
      position: absolute;
      left: 0;
      width: 100%;
      display: block;
      height: 3px;
      background-color: $white;
      @extend .transition-ease-in-out;

      &:first-child {
        transform: translate(0, -10px);
      }

      &:last-child {
        transform: translate(0, 10px);
      }
    }

    &.active {
      span {
        &:nth-child(1) {
          transform: rotateZ(45deg);
        }

        &:nth-child(2) {
          left: 100%;
        }

        &:nth-child(3) {
          transform: rotateZ(-45deg);
        }
      }
    }
  }
}

@keyframes headerActiveSticky {
  100% {
    top: 0;
  }
}