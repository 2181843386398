@import "src/assets/styles/index";

.founded-trader {
  &__swiper {
    &:nth-child(2) {
      margin-top: 0 !important;
    }

    &-item {
      @extend .transition-ease-in-out;
      @include sm {
        margin: auto;
      }

      &__container {
        width: 100%;
        @include sm {
          margin: auto;
          max-width: 348px;
        }
      }

      &__wrapper {
        width: 100%;
        padding-bottom: 100%;
        border-radius: 100%;
        overflow: hidden;
        position: relative;
        border: 2px solid $primary;
      }

      &__image {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
      }

      &.swiper-slide {
        &-active {
          .founded-trader__swiper-item {
            &__container {
              @include sm {
                max-width: 725px;
              }
            }
          }

          &.one-child {
            .founded-trader__swiper-item__container {
              @include sm {
                width: calc(100% / 3);
              }
            }
          }
        }
      }
    }
  }

  &__line {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}