@import "variables";
@import "breakpoints";

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer  base {
  @font-face {
    font-family: 'Lemonmilk-Regular';
    src: url("../fonts/Lemonmilk-Regular.otf");
  }
  html {
    font-family: 'Lemonmilk-Regular', sans-serif;
  }

  body {
    overflow-y: auto;

    &:has(#staticModal[aria-hidden="false"]) {
      overflow: hidden;
    }
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: $black;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 30px;
    background: $white;
    @extend .transition-ease-in-out;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(225, 225, 225, .5);
  }
}

@layer components {
  .form {
    &-group {
      @apply space-y-6;
    }

    &-item {
      @apply w-full;
      @apply space-y-2.5;
      & > label {
        display: block;
      }

      & > input {
        width: 100%;
      }
    }

    &-error {
      display: block;
      color: $red;
      font-size: 12px;
      margin-top: 12px;
    }
  }

  .bg-image-primary {
    background-image: url("../images/page-bg.png");
    background-repeat: repeat-y;
    background-size: cover;
  }
}

@layer utilities {
  .transition-ease-in-out {
    transition: all ease-in-out 200ms;
  }

  .nav-link {
    @extend .transition-ease-in-out;
    font-size: 18px;
    color: inherit;
    width: fit-content;
    position: relative;
    padding-bottom: 2px;

    &:before {
      @extend .transition-ease-in-out;
      content: '';
      position: absolute;
      height: 2px;
      background-color: currentColor;
      left: 0;
      bottom: 0;
      width: 0;
    }

    &:hover {
      &:before {
        width: 100%;
      }
    }

    &.active {
      &:before {
        width: 100%;
      }
    }

    &__require {
      border: 1px solid $white;
      border-radius: 20px;
      padding: 2px 14px;
      @apply bg-primary/40;
      &:hover {
        background-color: $primary;

        &:before {
          width: 0;
        }
      }

      &.active {
        background-color: $primary;

        &:before {
          width: 0;
        }
      }
    }
  }

  .text-shadow-black-85 {
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.85);
  }

  .link-style {
    @apply underline;
    @apply cursor-pointer;
    @extend .transition-ease-in-out;

    &:hover {
      @apply text-primary/80;
    }
  }
}
