@import "src/assets/styles/index";

.checkbox {
  &-container {
    cursor: pointer;
    position: relative;
    padding-left: 40px;

    &:hover {
      .checkbox-input {
        & ~ .checkbox-checkmark {
          background-color: $gray;
        }
      }
    }
  }

  &-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkbox-checkmark {
      background-color: $primary !important;

      &:after {
        display: block;
      }
    }
  }

  &-checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: $gray-300;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 50%;
      top: 50%;
      width: 6px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
}